import React, {useState} from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import Header from '../components/header';
import Footer from '../components/footer';
import {ButtonComponent} from '../components/button';
import LottieIcon from '../components/lottie-icon';
import {LoadingCircle} from '../assets/lotties';
import BannerBgImg from '../assets/images/bannerbg-gradient-mask@3x.png';
import KmMidiaLogoImg from '../assets/images/km-midia@3x.png';
import {toastNotification} from '../components/tostify';

const Content = styled.div`
  display: flex;
  padding-left: 35px;
  padding-right: 35px;
  height: calc(100vh - 180px);
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    padding-left: 130px;
    padding-right: 0;
    width: 545px;
  }
`;

const Form = styled.form``;

const LoginTitle = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: #262626;
  margin-top: 0;

  @media (min-width: 1024px) {
    font-size: 34px;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  @media (min-width: 1024px) {
    width: 90%;
  }
`;

const ButtonPrimary = styled(ButtonComponent)`
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 2px;
  background-color: #0083e6;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;

  @media (min-width: 1024px) {
    width: 90%;
  }
`;


const FullWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const BannerContainer = styled.div`
  width: 50%;
  height: 100vh;
  background: url(${BannerBgImg}) top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
    height: 150px;
    background: url(${BannerBgImg}) center -400px no-repeat;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 1024px) {
    width: 50%;
  }
`;
/*
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-height: 750px;
`;
*/
const KmMidiaLogo = styled.img`
  height: 60px;

  @media (min-width: 1024px) {
    height: auto;
    margin-top: 6%;
  }
`;


const NovaSenha = () => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    senha: '',
    confirmarSenha: ''
  });

  const submitLogin = e => {
    e.preventDefault();

    const {senha, confirmarSenha} = fields;

    if (!senha || !confirmarSenha) {
      return toastNotification({message: 'Preencha os dois campos!', type: 'warning'})
    }

    if (senha !== confirmarSenha) {
      return toastNotification({message: 'Os valores informados nos dois campos não são iguais!', type: 'warning'})
    }

    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    return Axios.put(`https://sistema.kmmoney.com.br:8443/public/v1/usuario/redefinir-senha/${token}`, {senha})
      .then(() => toastNotification({message: 'Senha alterada com sucesso!', type: 'success'}))
      .catch(({response}) => toastNotification({message: response?.data?.message, type: 'error'}))
      .finally(() => setLoading(false));
  };

  return (

    <FullWrapper>
      <BannerContainer>
        <KmMidiaLogo src={KmMidiaLogoImg} alt="kmmidia" />
      </BannerContainer>
      <Container>
        <Header />
        <Content>
          <Form>
            <LoginTitle>
              Digite a sua nova senha
            </LoginTitle>
            <StyledTextField
              label="Senha"
              margin="normal"
              name="senha"
              type="password"
              value={fields.senha}
              autoComplete="new-password"
              onChange={e => {
                setFields({
                  ...fields,
                  senha: e.target.value
                });
              }}
            />
            <StyledTextField
              label="Confirmar senha"
              margin="normal"
              name="confirmarSenha"
              type="password"
              value={fields.confirmarSenha}
              autoComplete="new-password"
              onChange={e => {
                setFields({
                  ...fields,
                  confirmarSenha: e.target.value
                });
              }}
            />
            <ButtonPrimary
              value={'Enviar'}
              onClick={submitLogin}
              rightIcon={loading ? <LottieIcon icon={LoadingCircle} /> : null}
            />
          </Form>
        </Content>
        <Footer />
      </Container>
    </FullWrapper>
  );
};

export default NovaSenha;
